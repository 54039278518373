
.main-box {
  height: 100%;
}

.main-wrapper {
  height: 100%;

  ::v-deep > .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
